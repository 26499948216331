import { Box, Heading } from '@chakra-ui/react';

function Automations() {
  return (
    <Box p={4}>
      <Heading>Automations</Heading>
      <p>Content for automations goes here.</p>
    </Box>
  );
}

export default Automations;
