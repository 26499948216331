import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import { collection, getDocs, query, orderBy, doc, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const fieldnames = [
  "first_name", "last_name", "full_name", "person_linkedin_url",
  "person_title", "person_headline", "job_id", "job_title", "job_url",
  "job_referenceId", "job_company_name", "job_company_logo", "job_company_url",
  "job_location", "job_type", "job_postDate", "job_postAt", "job_postedTimestamp",
  "job_benefits", "company_website", "email", "status", "result", "score"
];

function LeadSheets() {
  const [leadSheets, setLeadSheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queueLength, setQueueLength] = useState(null);
  const [queueLoading, setQueueLoading] = useState(true);

  const fetchLeadSheets = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'sheets'), orderBy('created_at', 'desc'));
      const querySnapshot = await getDocs(q);
      const sheets = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLeadSheets(sheets);
    } catch (error) {
      console.error('Error fetching lead sheets: ', error);
    }
    setLoading(false);
  };

  const fetchQueueLength = async () => {
    setQueueLoading(true);
    try {
      const response = await axios.get('https://horizon-leads-server.onrender.com/queue-length');
      setQueueLength(response.data.queue_length);
    } catch (error) {
      console.error('Error fetching queue length: ', error);
    }
    setQueueLoading(false);
  };

  const handleRefresh = () => {
    fetchLeadSheets();
    fetchQueueLength();
  };

  const mapDataToFieldnames = (data) => {
    return data.map(item => {
      const mappedItem = {};
      fieldnames.forEach(field => {
        mappedItem[field] = item[field] || '';
      });
      return mappedItem;
    });
  };

  const downloadData = (data, type, fileName) => {
    const mappedData = mapDataToFieldnames(data);

    if (type === 'json') {
      const json = JSON.stringify(mappedData, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      saveAs(blob, `${fileName}.json`);
    } else if (type === 'csv' || type === 'xlsx') {
      const worksheet = XLSX.utils.json_to_sheet(mappedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      if (type === 'csv') {
        XLSX.writeFile(workbook, `${fileName}.csv`, { bookType: 'csv' });
      } else {
        XLSX.writeFile(workbook, `${fileName}.xlsx`, { bookType: 'xlsx' });
      }
    }
  };

  const handleDownload = async (sheet, type) => {
    try {
      const docRef = doc(db, 'sheets', sheet.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data().data;
        const fileName = `${sheet.sheet_name}`;
        downloadData(data, type, fileName);
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error downloading document: ', error);
    }
  };

  useEffect(() => {
    fetchLeadSheets();
    fetchQueueLength();
  }, []);

  if (loading || queueLoading) {
    return <Spinner />;
  }

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading>Lead Sheets</Heading>
        <Button onClick={handleRefresh} colorScheme="blue">Refresh</Button>
      </Flex>
      <Box mt={4}>
        <Heading size="md">Queue Number: {queueLength === 0 ? 0 : queueLength}</Heading>
      </Box>
      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>Sheet Name</Th>
            <Th>Created At</Th>
            <Th>State</Th>
          </Tr>
        </Thead>
        <Tbody>
          {leadSheets.map(sheet => (
            <Tr key={sheet.id}>
              <Td>{sheet.sheet_name}</Td>
              <Td>{new Date(sheet.created_at.seconds * 1000).toLocaleString()}</Td>
              <Td>
                {sheet.state === 'completed' ? (
                  <Menu>
                    <MenuButton as={Button} colorScheme="blue">
                      Download
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => handleDownload(sheet, 'json')}>JSON</MenuItem>
                      <MenuItem onClick={() => handleDownload(sheet, 'csv')}>CSV</MenuItem>
                      <MenuItem onClick={() => handleDownload(sheet, 'xlsx')}>Excel</MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  sheet.state
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default LeadSheets;
