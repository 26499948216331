const jobTitlesOptions = [
    // Software and IT Positions
    "Software Engineer",
    "Data Scientist",
    "Product Manager",
    "Designer",
    "Marketing Specialist",
    "Sales Representative",
    "CIO",
    "Chief Information Officer",
    "CTO",
    "Chief Technological Officer",
    "Vice President Engineering",
    "VP of Engineering",
    "VP of Design",
    "Vice President of Design",
    "IT Manager",
    "Systems Administrator",
    "Network Engineer",
    "DevOps Engineer",
    "Full Stack Developer",
    "Frontend Developer",
    "Backend Developer",
    "Mobile Developer",
    "Security Analyst",
    "Cloud Architect",
    "Data Engineer",
    "Machine Learning Engineer",
    "AI Specialist",
    "Technical Support Specialist",
    "QA Engineer",
    "Database Administrator",
    "Business Analyst",
    "Scrum Master",
    "Project Manager",
    "IT Consultant",
    "Software Architect",
  
    // HR and Recruiting Positions
    "HR Lead",
    "Head of HR",
    "HR Coordinator",
    "HR Manager",
    "Hiring Manager",
    "Human Resources Manager",
    "Human Resources",
    "HR Director",
    "Talent Manager",
    "Talent Acquisition",
    "Talent Acquisition Specialist",
    "Talent Scout",
    "Recruiter",
    "Senior Recruiter",
    "Recruiting Manager",
    "Staffing Specialist",
    "Employee Relations Specialist",
    "Compensation and Benefits Manager",
    "Training and Development Manager",
    "Organizational Development Manager",
    "Diversity and Inclusion Specialist",
    "HR Business Partner",
  
    // Legal Positions
    "Lawyer",
    "Attorney",
    "Corporate Counsel",
    "In-House Counsel",
    "Paralegal",
    "Legal Assistant",
    "Legal Secretary",
    "Compliance Officer",
    "Contract Manager",
    "Legal Analyst",
    "Litigation Support Specialist",
    "General Counsel",
    "Associate Attorney",
    "Partner",
    "Junior Attorney",
    "Senior Attorney",
    "Patent Attorney",
    "Intellectual Property Lawyer",
    "Employment Lawyer",
    "Real Estate Lawyer",
    "Tax Lawyer",
    "Family Lawyer",
    "Criminal Lawyer",
    "Commercial Lawyer",
    "Personal Injury Lawyer",
  
    // Business and Executive Positions
    "CEO",
    "Chief Executive Officer",
    "COO",
    "Chief Operating Officer",
    "CFO",
    "Chief Financial Officer",
    "CMO",
    "Chief Marketing Officer",
    "CSO",
    "Chief Sales Officer",
    "President",
    "Vice President",
    "Director",
    "Managing Director",
    "Executive Director",
    "General Manager",
    "Operations Manager",
    "Business Development Manager",
    "Account Manager",
    "Sales Manager",
    "Marketing Manager",
    "Finance Manager",
    "Customer Service Manager",
    "Office Manager",
    "Administrative Manager",
    "Operations Coordinator",
    "Project Coordinator",
    "Client Relations Manager",
    "Customer Success Manager",
    "Community Manager",
    "Brand Manager",
    "Product Marketing Manager",
    "Digital Marketing Manager",
    "Content Manager",
    "PR Manager",
    "Communications Manager",
    "Social Media Manager",
    "Event Manager",
    "Creative Director",
    "Art Director",
    "UX/UI Designer",
    "Graphic Designer",
    "Web Designer",
    "Copywriter",
    "Content Strategist",
    "SEO Specialist",
    "PPC Specialist",
    "Market Research Analyst",
    "Business Analyst",
    "Financial Analyst",
    "Accountant",
    "Controller",
    "Auditor",
    "Tax Specialist",
    "Payroll Specialist",
    "Supply Chain Manager",
    "Logistics Manager",
    "Purchasing Manager",
    "Procurement Manager",
    "Inventory Manager",
    "Warehouse Manager",
    "Production Manager",
    "Quality Control Manager",
    "Safety Manager",
    "Maintenance Manager",
    "Facilities Manager",
    "Environmental Health and Safety Manager",
    "Engineering Manager",
    "Technical Manager",
    "R&D Manager",
    "Innovation Manager",
    "Product Development Manager",
    "Quality Assurance Manager",
    "Compliance Manager",
    "Regulatory Affairs Manager",
    "Sustainability Manager",
    "Corporate Social Responsibility Manager",
    "IT Manager",
    "Information Security Manager",
    "Data Privacy Manager",
    "Risk Manager",
    "Internal Auditor",
    "Project Manager",
    "Program Manager",
    "Portfolio Manager",
    "Change Manager",
    "Transformation Manager",
    "Business Continuity Manager",
    "Crisis Manager",
    "Emergency Manager",
    "Disaster Recovery Manager",
    "Security Manager",
    "Facilities Security Manager",
    "Cybersecurity Manager",
    "Incident Manager",
    "Service Delivery Manager",
    "Vendor Manager",
    "Supplier Manager",
    "Partnership Manager",
    "Franchise Manager",
    "Distribution Manager",
    "Channel Manager",
    "Alliance Manager",
    "Client Manager",
    "Customer Relations Manager",
    "Member Services Manager",
    "Visitor Services Manager",
    "Guest Services Manager",
    "Hospitality Manager",
    "Travel Manager",
    "Event Coordinator",
    "Event Planner",
    "Exhibition Manager",
    "Conference Manager",
    "Meeting Planner",
    "Wedding Planner",
    "Catering Manager",
    "Banquet Manager",
    "Restaurant Manager",
    "Bar Manager",
    "Cafe Manager",
    "Food and Beverage Manager",
    "Hotel Manager",
    "Resort Manager",
    "Casino Manager",
    "Cruise Director",
    "Tour Manager",
    "Tour Guide",
    "Travel Agent",
    "Flight Attendant",
    "Pilot",
    "Co-Pilot",
    "Aircraft Mechanic",
    "Air Traffic Controller",
    "Airport Manager",
    "Port Manager",
    "Harbor Master",
    "Dock Manager",
    "Ship Captain",
    "First Mate",
    "Deckhand",
    "Marine Engineer",
    "Naval Architect",
    "Oceanographer",
    "Marine Biologist",
    "Environmental Scientist",
    "Ecologist",
    "Conservation Scientist",
    "Wildlife Biologist",
    "Zoologist",
    "Botanist",
    "Agricultural Scientist",
    "Food Scientist",
    "Soil Scientist",
    "Forester",
    "Park Ranger",
    "Fisheries Manager",
    "Aquaculture Manager",
    "Veterinarian",
    "Veterinary Technician",
    "Animal Trainer",
    "Animal Caretaker",
    "Pet Groomer",
    "Zookeeper",
    "Kennel Manager",
    "Pet Store Manager",
    "Pet Sitter",
    "Dog Walker",
    "Equine Manager",
    "Stable Manager",
    "Ranch Manager",
    "Farm Manager",
    "Agricultural Manager",
    "Crop Manager",
    "Livestock Manager",
    "Dairy Manager",
    "Poultry Manager",
    "Horticulturist",
    "Arborist",
    "Landscaper",
    "Landscape Architect",
    "Groundskeeper",
    "Greenhouse Manager",
    "Nursery Manager",
    "Golf Course Superintendent",
    "Sports Turf Manager",
    "Irrigation Manager",
    "Park Manager",
    "Recreation Manager",
    "Recreation Director",
    "Fitness Manager",
    "Wellness Manager",
    "Spa Manager",
    "Salon Manager",
    "Personal Trainer",
    "Fitness Instructor",
    "Yoga Instructor",
    "Pilates Instructor",
    "Dance Instructor",
    "Swim Instructor",
    "Lifeguard",
    "Aquatics Manager",
    "Sports Coach",
    "Athletic Trainer",
    "Strength and Conditioning Coach",
    "Sports Agent",
    "Sports Marketing Manager",
    "Sports Information Director",
    "Sports Journalist",
    "Sports Photographer",
    "Sports Broadcaster",
    "Sports Commentator",
    "Sports Analyst",
    "Event Promoter",
    "Ticket Manager",
    "Merchandising Manager",
    "Concessions Manager",
    "Facility Manager",
    "Stadium Manager",
    "Arena Manager",
    "Theater Manager",
    "Museum Manager",
    "Gallery Manager",
    "Exhibit Manager",
    "Curator",
    "Archivist",
    "Conservator",
    "Art Handler",
    "Art Installer",
    "Art Registrar",
    "Art Preparator",
    "Art Conservator",
    "Art Restorer",
    "Art Appraiser",
    "Art Consultant",
    "Art Dealer",
    "Art Gallery Assistant",
    "Art Gallery Director",
    "Museum Educator",
    "Museum Interpreter",
    "Tour Guide",
    "Docent",
    "Exhibit Designer",
    "Exhibit Fabricator",
    "Exhibit Installer",
    "Exhibit Technician",
    "Museum Technician",
    "Museum Assistant",
    "Museum Director",
    "Historic Site Manager",
    "Historic Preservationist",
    "Historic Preservation Specialist",
    "Cultural Resource Manager",
    "Anthropologist",
    "Archaeologist",
    "Ethnographer",
    "Linguist",
    "Sociologist",
    "Psychologist",
    "Counselor",
    "Therapist",
    "Social Worker",
    "Case Manager",
    "Case Worker",
    "Mental Health Specialist",
    "Mental Health Counselor",
    "School Counselor",
    "Guidance Counselor",
    "Academic Advisor",
    "Career Counselor",
    "Career Coach",
    "Life Coach",
    "Executive Coach",
    "Business Coach",
    "Health Coach",
    "Wellness Coach",
    "Nutritionist",
    "Dietitian",
    "Health Educator",
    "Community Health Worker",
    "Public Health Specialist",
    "Epidemiologist",
    "Biostatistician",
    "Health Services Manager",
    "Healthcare Administrator",
    "Hospital Administrator",
    "Clinic Manager",
    "Practice Manager",
    "Medical Office Manager",
    "Medical Receptionist",
    "Medical Secretary",
    "Medical Assistant",
    "Certified Medical Assistant",
    "Patient Care Coordinator",
    "Patient Advocate",
    "Patient Services Representative",
    "Medical Biller",
    "Medical Coder",
    "Health Information Technician",
    "Health Information Manager",
    "Medical Records Technician",
    "Medical Transcriptionist",
    "Medical Interpreter",
    "Medical Scribe",
    "Clinical Research Coordinator",
    "Clinical Research Associate",
    "Clinical Trials Manager",
    "Research Scientist",
    "Biomedical Scientist",
    "Biochemist",
    "Biophysicist",
    "Biotechnologist",
    "Microbiologist",
    "Immunologist",
    "Geneticist",
    "Pharmacologist",
    "Toxicologist",
    "Physiologist",
    "Pathologist",
    "Epidemiologist",
    "Biostatistician",
    "Bioinformatics Specialist",
    "Biomaterials Scientist",
    "Bioengineer",
    "Biomedical Engineer",
    "Clinical Engineer",
    "Rehabilitation Engineer",
    "Medical Device Engineer",
    "Medical Device Sales Representative",
    "Pharmaceutical Sales Representative",
    "Medical Science Liaison",
    "Pharmacist",
    "Pharmacy Technician",
    "Pharmacy Assistant",
    "Pharmacy Manager",
    "Pharmacy Director",
    "Pharmaceutical Research Scientist",
  ];
  
  export default jobTitlesOptions;
  