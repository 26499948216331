// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCzM9IMb8EUnwBTgOQQdTlMoDcZN3utvtc",
  authDomain: "horizon-leads.firebaseapp.com",
  projectId: "horizon-leads",
  storageBucket: "horizon-leads.appspot.com",
  messagingSenderId: "115168789470",
  appId: "1:115168789470:web:1f2023f2becec30d40534f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firestore
export const db = getFirestore(app);
