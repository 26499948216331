import { Box, Input, Button, VStack, Heading, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../Firebase';
import { useAuth } from './AuthContext';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const { isAuthenticated, isVerified } = useAuth(); 

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (isVerified) {
        navigate('/printer');
      } else {
        toast({
          title: 'Verification required.',
          description: 'You must be verified to enter the printer.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Login failed.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <VStack spacing={4} align="stretch">
        <Heading>Login</Heading>
        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
        <Input
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        <Button onClick={handleLogin} colorScheme="blue">
          Submit
        </Button>
        {isAuthenticated && (
          <Button onClick={() => {
            if (isVerified) {
              navigate('/printer');
            } else {
              toast({
                title: 'Verification required.',
                description: 'You must be verified to enter the printer.',
                status: 'warning',
                duration: 3000,
                isClosable: true,
              });
            }
          }} colorScheme="green">
            Go to Printer
          </Button>
        )}
      </VStack>
    </Box>
  );
}

export default LoginPage;
