import React, { useState } from "react";
import {
  Box,
  Heading,
  Select,
  Input,
  Button,
  Switch,
  Text,
  HStack,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  Tooltip,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import JobTitlePicker from './JobTitlePicker';

const personLocationsOptions = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "Germany",
  "India",
];

const industryOptions = [
  "Staffing and Recruiting",
  "Healthcare",
  "Finance",
  "Education",
  "Manufacturing",
  "Retail",
];

const employeeRanges = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10001+",
];

function Gen() {
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState({
    keywords: "",
    locationId: "103644278",
    datePosted: "",
    salary: "",
    jobType: "",
    experienceLevel: "",
    onsiteRemote: "",
    sort: "",
    page: 1,
    perPage: 1,
    hardStopPage: 1,
    hardStopEnabled: true,
    personLocations: [],
    companyIndustry: [],
    employeeRange: [],
  });

  const [companyList, setCompanyList] = useState("");
  const [jobTitles, setJobTitles] = useState([]);
  const [removeDuplicates, setRemoveDuplicates] = useState(true);
  const [removeRecruitingAgencies, setRemoveRecruitingAgencies] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false); // State for button disable

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQueryParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setQueryParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleNumberChange = (name, value) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleAddPersonLocation = (location) => {
    if (!queryParams.personLocations.includes(location)) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        personLocations: [...prevParams.personLocations, location],
      }));
    }
  };

  const handleRemovePersonLocation = (location) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      personLocations: prevParams.personLocations.filter((loc) => loc !== location),
    }));
  };

  const handleAddIndustry = (industry) => {
    if (!queryParams.companyIndustry.includes(industry)) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        companyIndustry: [...prevParams.companyIndustry, industry],
      }));
    }
  };

  const handleRemoveIndustry = (industry) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      companyIndustry: prevParams.companyIndustry.filter((ind) => ind !== industry),
    }));
  };

  const handleAddEmployeeRange = (range) => {
    if (!queryParams.employeeRange.includes(range)) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        employeeRange: [...prevParams.employeeRange, range],
      }));
    }
  };

  const handleRemoveEmployeeRange = (range) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      employeeRange: prevParams.employeeRange.filter((r) => r !== range),
    }));
  };

  const generateLeadSheet = async () => {
    setIsGenerating(true); // Disable button
    const formData = {
      queryParams: {
        ...queryParams,
        companyList: companyList.split("\n").filter(Boolean),
        jobTitles,
        removeDuplicates,
        removeRecruitingAgencies,
      },
    };
    console.log("Query Params:", JSON.stringify(formData.queryParams, null, 2));

    try {
      const response = await axios.post("https://horizon-leads-server.onrender.com/run-scripts", formData);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error generating lead sheet:", error);
    } finally {
      setIsGenerating(false); // Re-enable button
      setTimeout(() => {
        navigate('/printer/lead-sheets');
      }, 1000);
    }
  };

  return (
    <Box p={4}>
      <Heading mb={4}>Generate Lead Sheet</Heading>

      <Box mt={4} width="500px">
        <Heading as="h4" size="md" mt={4}>Jobs Parameters</Heading>
        
        <HStack mt={4}>
          <Heading as="h4" size="sm">🔍 Keywords (Job Title To Search On Linkedin)</Heading>
          <Tooltip label="Enter keywords related to the job position you're looking for (e.g., 'Software Engineer').">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Input
          placeholder="Keywords (e.g., Software Engineer)"
          name="keywords"
          value={queryParams.keywords}
          onChange={handleInputChange}
          mt={4}
          width="100%"
        />

        <HStack mt={10}>
          <Heading as="h4" size="sm">🌎 Location</Heading>
          <Tooltip label="Select the location where you're looking for jobs.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          name="locationId"
          value={queryParams.locationId}
          onChange={handleSelectChange}
          mt={4}
          width="100%"
        >
          <option value="92000000">Anywhere</option>
          <option value="103644278">United States</option>
        </Select>

        <HStack mt={10}>
          <Heading as="h4" size="sm">📅 Date Posted</Heading>
          <Tooltip label="Filter jobs by the date they were posted.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          name="datePosted"
          value={queryParams.datePosted}
          onChange={handleSelectChange}
          mt={4}
          width="100%"
        >
          <option value="">Date Posted (None)</option>
          <option value="anyTime">Any Time</option>
          <option value="pastMonth">Past Month</option>
          <option value="pastWeek">Past Week</option>
          <option value="past24Hours">Past 24 Hours</option>
        </Select>

        <HStack mt={10}>
          <Heading as="h4" size="sm">💰 Salary</Heading>
          <Tooltip label="Filter jobs by the salary range.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          name="salary"
          value={queryParams.salary || ""}
          onChange={handleSelectChange}
          mt={4}
          width="100%"
        >
          <option value="">Salary (None)</option>
          <option value="40k+">40k+</option>
          <option value="60k+">60k+</option>
          <option value="80k+">80k+</option>
          <option value="100k+">100k+</option>
          <option value="120k+">120k+</option>
          <option value="140k+">140k+</option>
          <option value="160k+">160k+</option>
          <option value="180k+">180k+</option>
          <option value="200k+">200k+</option>
        </Select>

        <HStack mt={10}>
          <Heading as="h4" size="sm">🕒 Job Type</Heading>
          <Tooltip label="Select the type of job (e.g., Full Time, Part Time).">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          name="jobType"
          value={queryParams.jobType}
          onChange={handleSelectChange}
          mt={4}
          width="100%"
        >
          <option value="">Job Type (None)</option>
          <option value="fullTime">Full Time</option>
          <option value="partTime">Part Time</option>
          <option value="contract">Contract</option>
          <option value="internship">Internship</option>
        </Select>

        <HStack mt={10}>
          <Heading as="h4" size="sm">📈 Experience Level</Heading>
          <Tooltip label="Filter jobs by the required experience level.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          name="experienceLevel"
          value={queryParams.experienceLevel || ""}
          onChange={handleSelectChange}
          mt={4}
          width="100%"
        >
          <option value="">Experience Level (None)</option>
          <option value="internship">Internship</option>
          <option value="associate">Associate</option>
          <option value="director">Director</option>
          <option value="entryLevel">Entry Level</option>
          <option value="midSeniorLevel">Mid Senior Level</option>
          <option value="executive">Executive</option>
        </Select>

        <HStack mt={10}>
          <Heading as="h4" size="sm">🏠 Onsite/Remote</Heading>
          <Tooltip label="Specify whether the job is onsite, remote, or hybrid.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          name="onsiteRemote"
          value={queryParams.onsiteRemote || ""}
          onChange={handleSelectChange}
          mt={4}
          width="100%"
        >
          <option value="">Onsite/Remote (None)</option>
          <option value="onSite">On Site</option>
          <option value="remote">Remote</option>
          <option value="hybrid">Hybrid</option>
        </Select>

        <HStack mt={10}>
          <Heading as="h4" size="sm">⚖️ Sort</Heading>
          <Tooltip label="Sort the job listings by relevance or recency.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          name="sort"
          value={queryParams.sort}
          onChange={handleSelectChange}
          mt={4}
          width="100%"
        >
          <option value="">Sort (None)</option>
          <option value="mostRelevant">Most Relevant</option>
          <option value="mostRecent">Most Recent</option>
        </Select>

        <HStack mt={10}>
          <Text fontWeight="bold">🛑 Hard Stop Enabled</Text>
          <Tooltip label="Toggle to hard stop after the specified number of pages. (If this is turned ON it will STOP after the number of pages. If this is turned OFF it will go until there is no more pages based on the amount of jobs BE CAREFUL)">
            <InfoOutlineIcon />
          </Tooltip>
          <Switch
            isChecked={queryParams.hardStopEnabled}
            onChange={(e) => handleNumberChange("hardStopEnabled", e.target.checked)}
          />
        </HStack>

        <HStack mt={4}>
          <Text>🗐 How Many Pages it Should Scrape</Text>
          <Tooltip label="Specify how many pages to scrape.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <NumberInput
          value={queryParams.hardStopPage}
          onChange={(valueString) => handleNumberChange("hardStopPage", parseInt(valueString))}
          min={1}
          mt={2}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>

      <Box mt={10} h="2px" w="500px" bg="#e6e6e6"></Box>

      <Box mt={10} width="500px">
        <Heading as="h4" size="md" mt={4}>Formatting and Company Selection</Heading>

        <HStack mt={4}>
          <Text fontWeight="bold">🔄 Remove Duplicates</Text>
          <Tooltip label="Toggle to remove duplicate job listings.">
            <InfoOutlineIcon />
          </Tooltip>
          <Switch
            isChecked={removeDuplicates}
            onChange={(e) => setRemoveDuplicates(e.target.checked)}
          />
        </HStack>

        <HStack mt={4}>
          <Text fontWeight="bold">👨‍💼 Remove Known Recruiting Agencies</Text>
          <Tooltip label="Toggle to remove job listings from known recruiting agencies.">
            <InfoOutlineIcon />
          </Tooltip>
          <Switch
            isChecked={removeRecruitingAgencies}
            onChange={(e) => setRemoveRecruitingAgencies(e.target.checked)}
          />
        </HStack>

        <HStack mt={10}>
          <Heading as="h4" size="sm">🏢 Companies to Remove</Heading>
          <Tooltip label="List the companies to exclude from the job search, one per line.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Textarea
          placeholder="1 line for every company name"
          value={companyList}
          onChange={(e) => setCompanyList(e.target.value)}
          width="100%"
          mt={4}
        />

        <JobTitlePicker jobTitles={jobTitles} setJobTitles={setJobTitles} />

        <HStack mt={10}>
          <Heading as="h4" size="sm">📍 Person Locations</Heading>
          <Tooltip label="Select the locations of the people you're targeting.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          placeholder="Select Person Location"
          onChange={(e) => handleAddPersonLocation(e.target.value)}
          mt={4}
          width="100%"
        >
          {personLocationsOptions.map((location, index) => (
            <option key={index} value={location}>
              {location}
            </option>
          ))}
        </Select>
        <VStack mt={2} align="start">
          {queryParams.personLocations.map((location, index) => (
            <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="green">
              <TagLabel>{location}</TagLabel>
              <TagCloseButton onClick={() => handleRemovePersonLocation(location)} />
            </Tag>
          ))}
        </VStack>

        <HStack mt={10}>
          <Heading as="h4" size="sm">🏭 Exclude Industries</Heading>
          <Tooltip label="Select industries to exclude from the search.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          placeholder="Select Industry"
          onChange={(e) => handleAddIndustry(e.target.value)}
          mt={4}
          width="100%"
        >
          {industryOptions.map((industry, index) => (
            <option key={index} value={industry}>
              {industry}
            </option>
          ))}
        </Select>
        <VStack mt={2} align="start">
          {queryParams.companyIndustry.map((industry, index) => (
            <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="red">
              <TagLabel>{industry}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveIndustry(industry)} />
            </Tag>
          ))}
        </VStack>

        <HStack mt={10}>
          <Heading as="h4" size="sm">👥 Employee Ranges</Heading>
          <Tooltip label="Select employee ranges to include in the search.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <Select
          placeholder="Select Employee Range"
          onChange={(e) => handleAddEmployeeRange(e.target.value)}
          mt={4}
          width="100%"
        >
          {employeeRanges.map((range, index) => (
            <option key={index} value={range}>
              {range}
            </option>
          ))}
        </Select>
        <VStack mt={2} align="start">
          {queryParams.employeeRange.map((range, index) => (
            <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="blue">
              <TagLabel>{range}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveEmployeeRange(range)} />
            </Tag>
          ))}
        </VStack>

        <HStack mt={10}>
          <Text>🔟 Amount of Contacts to Find Per Company</Text>
          <Tooltip label="Specify the number of contacts to find per page.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <NumberInput
          value={queryParams.perPage}
          onChange={(valueString) => handleNumberChange("perPage", parseInt(valueString))}
          min={1}
          mt={4}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <HStack mt={10}>
          <Text>🔢 Page Number to Search (keep it on 1st)</Text>
          <Tooltip label="Specify the page number to start the search from.">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
        <NumberInput
          value={queryParams.page}
          onChange={(valueString) => handleNumberChange("page", parseInt(valueString))}
          min={1}
          mt={4}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <Button
          colorScheme="blue"
          mt={4}
          onClick={generateLeadSheet}
          isDisabled={isGenerating} // Disable button if isGenerating is true
        >
          📄 Generate Lead Sheet
        </Button>
      </Box>
    </Box>
  );
}

export default Gen;
