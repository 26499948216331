import { Box, Heading } from '@chakra-ui/react';

function DB() {
  return (
    <Box p={4}>
      <Heading>DB</Heading>
      <p>Content for generating lead sheets goes here.</p>
    </Box>
  );
}

export default DB;
