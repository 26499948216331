import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import PrinterPage from './components/PrinterPage';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

import Gen from './components/dashboard/Gen';
import Automations from './components/dashboard/Automations';
import DB from './components/dashboard/DB';
import LeadSheets from './components/dashboard/LeadSheets';

// Extend the theme to include custom fonts
const theme = extendTheme({
  fonts: {
    heading: "'Reddit Sans', sans-serif",
    body: "'Reddit Sans', sans-serif",
  }
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/printer" element={<ProtectedRoute><PrinterPage /></ProtectedRoute>}>
              <Route index element={<Gen />} />
              <Route path="gen" element={<Gen />} />
              <Route path="automations" element={<Automations />} />
              <Route path="database" element={<DB />} />
              <Route path="lead-sheets" element={<LeadSheets />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
