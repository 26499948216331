import { Box, Button, VStack, Heading, Icon, Spacer } from '@chakra-ui/react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FaFileAlt, FaRobot, FaDatabase, FaListAlt, FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from './AuthContext'; // Adjust the import path as necessary
import { signOut } from "firebase/auth";
import { auth } from '../Firebase'; // Adjust the import path as necessary

function PrinterPage() {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      navigate('/');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const menuItems = [
    { to: 'gen', icon: FaFileAlt, label: 'Generate Lead Sheet' },
    { to: 'automations', icon: FaRobot, label: 'Automations' },
    { to: 'database', icon: FaDatabase, label: 'Lead Database' },
    { to: 'lead-sheets', icon: FaListAlt, label: 'Lead Sheets' },
  ];

  return (
    <Box display="flex" height="100vh">
      <Box
        width="300px"
        bg="gray.100"
        p={4}
        display="flex"
        flexDirection="column"
        position="sticky"
        top="0"
        height="100vh"
      >
        <Heading as="h1" size="lg" mt={4} mb={8}>
          HRZN Leads 📇
        </Heading>
        <VStack spacing={4} align="stretch" flex="1">
          {menuItems.map((item, index) => (
            <Button
              key={index}
              as={Link}
              to={item.to}
              leftIcon={<Icon as={item.icon} />}
              colorScheme="blue"
              variant="solid"
              justifyContent="flex-start"
            >
              {item.label}
            </Button>
          ))}
          <Spacer />
          <Button
            leftIcon={<Icon as={FaSignOutAlt} />}
            colorScheme="red"
            variant="solid"
            justifyContent="flex-start"
            onClick={handleLogout}
            sx={{
                backgroundColor: 'transparent',
                color: 'initial',
                _hover: {
                backgroundColor: 'red.500',
                color: 'white',
                },
            }}
            >
            Logout
          </Button>
        </VStack>
      </Box>
      <Box flex="1" p={4} overflowY="auto">
        <Outlet />
      </Box>
    </Box>
  );
}

export default PrinterPage;
