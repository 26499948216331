import React, { useState } from 'react';
import {
  Box,
  Input,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Tooltip,
  HStack,
  Heading,
  List,
  ListItem,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import jobTitlesOptions from './ListJobs';

const JobTitlePicker = ({ jobTitles, setJobTitles }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const filteredJobTitles = jobTitlesOptions.filter((title) =>
    title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddJobTitle = (title) => {
    if (!jobTitles.includes(title)) {
      setJobTitles((prevTitles) => [...prevTitles, title]);
    }
    setSearchTerm(''); // Clear search term after adding
  };

  const handleRemoveJobTitle = (title) => {
    setJobTitles((prevTitles) => prevTitles.filter((t) => t !== title));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && searchTerm) {
      handleAddJobTitle(searchTerm);
    }
  };

  return (
    <Box>
      <HStack mt={10}>
        <Heading as="h4" size="sm">💼 Job Titles to Include (The job titles to find at the companies ex. I want to find the HR people at the company)</Heading>
        <Tooltip label="Select the job titles you want to include in your search.">
          <InfoOutlineIcon />
        </Tooltip>
      </HStack>
      <Input
        placeholder="Type job title..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
        mt={4}
        width="100%"
      />
      {searchTerm && (
        <List mt={2} maxH="200px" overflowY="auto" borderWidth="1px" borderRadius="md">
          {filteredJobTitles.map((title, index) => (
            <ListItem
              key={index}
              px={4}
              py={2}
              _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
              onClick={() => handleAddJobTitle(title)}
            >
              {title}
            </ListItem>
          ))}
        </List>
      )}
      <VStack mt={2} align="start">
        {jobTitles.map((title, index) => (
          <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="blue">
            <TagLabel>{title}</TagLabel>
            <TagCloseButton onClick={() => handleRemoveJobTitle(title)} />
          </Tag>
        ))}
      </VStack>
    </Box>
  );
};

export default JobTitlePicker;
